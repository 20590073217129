.admin-dashboard {
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #111;
  height: 35px;
  li {
    display: inline;
  }
  a {
    padding: 7px 15px;
    display: block;
    color: #666;
    float: left;
    &:hover {
      text-decoration: none;
      background: #222;
    }
  }
}