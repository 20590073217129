.dashboard-reports {
  margin-top: 40px;
}

.dashboard-report {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
}

.report-tab-link {
  font-size: 20px;
  margin-bottom: -1px !important;
  text-align: center !important;
  padding-left: 25px !important;
  background: $grey-secondary-5;
  border-color: $grey-secondary-25;
  color: $grey-primary;
}

#reportTabs {
  li {
    position: relative;
    a {
      &:hover {
        color: $red-light;
        background: $grey-secondary-5;
      }
    }
  }
  li.active {
    a {
      background-color: $red-light !important;
      border: 1px solid $red-light !important;
      color: $support-color-white;
    }
    .btn-refresh {
      display: block;
    }
  }
  .btn-refresh {
    background: $brand-primary;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
  }
}
