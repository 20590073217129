@mixin font-size($font-size: 16){
  font-size : #{$font-size}px;
  font-size : #{$font-size / 10}rem;
}

@mixin img-frame($padding: 4px){
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: $padding;
}

// Media Query Mixin
@mixin MQ($canvas){
  @if $canvas == XS{
     @media only screen and (min-width: $XS) { @content; }
  }
  @if $canvas == XSneg{
     @media only screen and (max-width: $XS - 1) { @content; }
  }
  @else if $canvas == S{
     @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == Sneg{
    @media only screen and (max-width: $S - 1) { @content; }
  }
  @else if $canvas == M{
     @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == Mneg{
     @media only screen and (max-width: $M - 1) { @content; }
  }
  @else if $canvas == L{
     @media only screen and (min-width: $L) { @content; }
  }
  @else if $canvas == XL{
     @media only screen and (min-width: $XL) { @content; }
  }
  @else if $canvas == S-M{
     @media only screen and (min-width: $S) and (max-width: $M - 1) { @content; }
  }
  @else if $canvas == M-L{
     @media only screen and (min-width: $M) and (max-width: $L - 1) { @content; }
  }
  @else if $canvas == L-XL{
     @media only screen and (min-width: $L) and (max-width: $XL - 1) { @content; }
  }
}

// Retina Background Images - Width & height need to be half the size of the 2x image
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

// Image replacement
@mixin ir{
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}


// Grid - 2 cols for small, 3 cols for medium, 4 cols for large
@mixin grid {
  @include make-row();
  > div {
    @include make-sm-column(6);
    @include make-md-column(4);
    @include make-lg-column(3);
    @include MQ(S-M) {
      &:nth-child(2n+1){
        clear: left;
      }
    }
    @include MQ(M-L) {
      &:nth-child(3n+1){
        clear: left;
      }
    }
    @include MQ(L) {
      &:nth-child(4n+1) {
        clear: left;
      }
    }
  }
}