// @import url(http://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic,700italic);

@import "variables";
@import "../bootstrap-sass/assets/stylesheets/bootstrap";
@import "mixins";
@import "utilities";
@import "external/nya-bs-select";
@import "external/bootstrap-select";

// Basscss
@import "bower_components/basscss-sass/scss/flexbox";
@import "bower_components/basscss-sass/scss/padding";
@import "bower_components/basscss-sass/scss/margin";
@import "bower_components/basscss-sass/scss/responsive-padding";
@import "bower_components/basscss-sass/scss/responsive-margin";

@import "components/modals";
@import "components/tour";
@import "components/header";
@import "components/dashboard";
@import "components/dashboard-reports";
@import "components/login";
@import "components/agm-screen";
@import "components/voting-card-login";
@import "components/voting-card-vote";
@import "components/voting-dashboard";
@import "components/questions";
@import "components/sidebar";
@import "components/home";
@import "components/toggleSwitch";
@import "components/buttons";
@import "components/registration";
@import "components/form";
@import "components/admin-nav";
@import "components/custom-panel";

// FontAwesome
$fa-font-path: "/fonts/fontawesome";
@import "bower_components/fontawesome/scss/font-awesome.scss";

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    /* from http://fonts.gstatic.com/s/lato/v11/zLhfkPOm_5ykmdm-wXaiuw.eot */
      url("/fonts/lato/Lato_300.eot?#iefix") format("embedded-opentype"),
    /* from http://fonts.gstatic.com/s/lato/v11/KT3KS9Aol4WfR6Vas8kNcg.woff */
      url("/fonts/lato/Lato_300.woff") format("woff"),
    /* from http://fonts.gstatic.com/s/lato/v11/EsvMC5un3kjyUhB9ZEPPwg.woff2 */
      url("/fonts/lato/Lato_300.woff2") format("woff2"),
    /* from http://fonts.gstatic.com/l/font?kit=7yKIrlBXX_AXuUv3Ts9_8g&skey=91f32e07d083dd3a#Lato */
      url("/fonts/lato/Lato_300.svg#Lato") format("svg"),
    /* from http://fonts.gstatic.com/s/lato/v11/nj47mAZe0mYUIySgfn0wpQ.ttf */
      url("/fonts/lato/Lato_300.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    /* from http://fonts.gstatic.com/s/lato/v11/nQhiC-wSiJx0pvEuJl8d8A.eot */
      url("/fonts/lato/Lato_400.eot?#iefix") format("embedded-opentype"),
    /* from http://fonts.gstatic.com/s/lato/v11/9k-RPmcnxYEPm8CNFsH2gg.woff */
      url("/fonts/lato/Lato_400.woff") format("woff"),
    /* from http://fonts.gstatic.com/s/lato/v11/1YwB1sO8YE1Lyjf12WNiUA.woff2 */
      url("/fonts/lato/Lato_400.woff2") format("woff2"),
    /* from http://fonts.gstatic.com/l/font?kit=hLECvlEj3pKlnS4NFs8NQw&skey=2d58b92a99e1c086#Lato */
      url("/fonts/lato/Lato_400.svg#Lato") format("svg"),
    /* from http://fonts.gstatic.com/s/lato/v11/v0SdcGFAl2aezM9Vq_aFTQ.ttf */
      url("/fonts/lato/Lato_400.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    /* from http://fonts.gstatic.com/s/lato/v11/sBtfDPlEIwvKKU53nAG7AQ.eot */
      url("/fonts/lato/Lato_700.eot?#iefix") format("embedded-opentype"),
    /* from http://fonts.gstatic.com/s/lato/v11/wkfQbvfT_02e2IWO3yYueQ.woff */
      url("/fonts/lato/Lato_700.woff") format("woff"),
    /* from http://fonts.gstatic.com/s/lato/v11/H2DMvhDLycM56KNuAtbJYA.woff2 */
      url("/fonts/lato/Lato_700.woff2") format("woff2"),
    /* from http://fonts.gstatic.com/l/font?kit=H4oiIt_Ug9TU5ast0nUT-w&skey=3480a19627739c0d#Lato */
      url("/fonts/lato/Lato_700.svg#Lato") format("svg"),
    /* from http://fonts.gstatic.com/s/lato/v11/DvlFBScY1r-FMtZSYIYoYw.ttf */
      url("/fonts/lato/Lato_700.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"),
    /* from http://fonts.gstatic.com/s/lato/v11/2HG_tEPiQ4Z6795cGfdivPY6323mHUZFJMgTvxaG2iE.eot */
      url("/fonts/lato/Lato_300italic.eot?#iefix") format("embedded-opentype"),
    /* from http://fonts.gstatic.com/s/lato/v11/2HG_tEPiQ4Z6795cGfdivD8E0i7KZn-EPnyo3HZu7kw.woff */
      url("/fonts/lato/Lato_300italic.woff") format("woff"),
    /* from http://fonts.gstatic.com/s/lato/v11/2HG_tEPiQ4Z6795cGfdivFtXRa8TVwTICgirnJhmVJw.woff2 */
      url("/fonts/lato/Lato_300italic.woff2") format("woff2"),
    /* from http://fonts.gstatic.com/l/font?kit=2HG_tEPiQ4Z6795cGfdivJbd9NUM7myrQQz30yPaGQ4&skey=8107d606b7e3d38e#Lato */
      url("/fonts/lato/Lato_300italic.svg#Lato") format("svg"),
    /* from http://fonts.gstatic.com/s/lato/v11/2HG_tEPiQ4Z6795cGfdivKCWcynf_cDxXwCLxiixG1c.ttf */
      url("/fonts/lato/Lato_300italic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"),
    /* from http://fonts.gstatic.com/s/lato/v11/bjaQ6jyWa8A2XFrSKceJyA.eot */
      url("/fonts/lato/Lato_400italic.eot?#iefix") format("embedded-opentype"),
    /* from http://fonts.gstatic.com/s/lato/v11/oUan5VrEkpzIazlUe5ieaA.woff */
      url("/fonts/lato/Lato_400italic.woff") format("woff"),
    /* from http://fonts.gstatic.com/s/lato/v11/PLygLKRVCQnA5fhu3qk5fQ.woff2 */
      url("/fonts/lato/Lato_400italic.woff2") format("woff2"),
    /* from http://fonts.gstatic.com/l/font?kit=sbmVKhXgM5wMsElf_vy3Iw&skey=51512958f8cff7a8#Lato */
      url("/fonts/lato/Lato_400italic.svg#Lato") format("svg"),
    /* from http://fonts.gstatic.com/s/lato/v11/LqowQDslGv4DmUBAfWa2Vw.ttf */
      url("/fonts/lato/Lato_400italic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
    /* from http://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYEPY6323mHUZFJMgTvxaG2iE.eot */
      url("/fonts/lato/Lato_700italic.eot?#iefix") format("embedded-opentype"),
    /* from http://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYED8E0i7KZn-EPnyo3HZu7kw.woff */
      url("/fonts/lato/Lato_700italic.woff") format("woff"),
    /* from http://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYEFtXRa8TVwTICgirnJhmVJw.woff2 */
      url("/fonts/lato/Lato_700italic.woff2") format("woff2"),
    /* from http://fonts.gstatic.com/l/font?kit=HkF_qI1x_noxlxhrhMQYEJbd9NUM7myrQQz30yPaGQ4&skey=5334e9c0b67702e2#Lato */
      url("/fonts/lato/Lato_700italic.svg#Lato") format("svg"),
    /* from http://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYEKCWcynf_cDxXwCLxiixG1c.ttf */
      url("/fonts/lato/Lato_700italic.ttf") format("truetype");
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background: #f0f0f0;
  margin-bottom: 30px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/material-design-icons/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(../fonts/material-design-icons/MaterialIcons-Regular.woff2)
      format("woff2"),
    url(../fonts/material-design-icons/MaterialIcons-Regular.woff)
      format("woff"),
    url(../fonts/material-design-icons/MaterialIcons-Regular.ttf)
      format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

// Btns

.btn {
  position: relative;
  // &:hover {
  //   &:before {
  //     content: '';
  //     background: rgba(0,0,0,0.1);
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //   }
  // }
  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none !important;
    box-shadow: none;
  }
  &:active {
    top: 1px;
  }
}

.btn-lg {
  padding: 10px 40px;
  font-size: 16px;
}

.form-control:focus {
  border-color: #999;
  box-shadow: none;
}

.bootstrap-select {
  .btn:focus {
    outline: none !important;
  }
}

.dropdown-menu {
  a:focus {
    outline: none !important;
  }
}

img {
  max-width: 100%;
  height: auto;
}

// Utitlities

.bold {
  font-weight: bold;
}

.text-lg {
  font-size: 20px;
}

// Tooltips

.tooltip-inner {
  max-width: 500px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  padding: 15px;
  font-size: 14px;
}

.tooltip.in {
  opacity: 1;
}

// Unsupported browser warning

.unsupported-browser-warning {
  border-radius: 0;
  margin-bottom: 0;
  .alert-inner {
    max-width: 550px;
    margin: 0 auto;
  }
  .fa {
    float: left;
    font-size: 30px;
    position: relative;
    top: 5px;
  }
  p {
    margin-left: 40px;
    margin-bottom: 0;
  }
}

/*
  Allow angular.js to be loaded in body, hiding cloaked elements until
  templates compile.  The !important is important given that there may be
  other selectors that are more specific or come later and might alter display.
 */
[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important;
}

// Animations

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.fa-spin {
  position: absolute;
  right: 13px;
  top: 13px;
}

// Management

.manage {
  .logo {
    @include MQ(Sneg) {
      display: none;
    }
  }
}

// Offline

.offline-message {
  h1 {
    margin-top: 50px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.mobile-app-links-wrapper {
  background: $support-color-white;
  border: 1px solid #ddd;
  padding: 30px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  @include MQ(S) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.mobile-app-links {
  @include MQ(S) {
    display: flex;
    justify-content: center;
  }
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}

.main-body-class {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.main-div-class {
  flex: 1 0 auto;
}

.root {
  display: flex;
}
.no-instr {
  width: auto !important;
  margin-top: 5px !important;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.show-window .modal-backdrop.in {
  opacity: 0 !important;
}

// .show-window{
//   overflow: auto;
// }

// .show-window .modal{
//   overflow: hidden !important;
// }

.modal .move {
  margin: 2px;
  font-size: 20px;
  cursor: pointer;
  color: $support-color-black;
}
.form-group.required .help-block {
  color: red;
}

.modal-askquestion {
  z-index: 99999 !important;
}


/** Custom CSS for DataTables **/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #000 !important;
  background-color: $support-color-white !important;
  border: 1px solid #ddd !important;
}

/* .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: $red-light !important;
  border: 1px solid $red-light !important;
  color: $support-color-white !important;
} */

.dataTables_wrapper .dataTables_filter input{
  padding: 6px 10px !important;
}


/** Custom SweetAlert2 - custom css for action buttons in alert popup **/
.swal2-title{
  color: $support-color-black !important;
}
button.swal2-styled{
  background: $red-light !important;
  border: 1px solid $red-light !important;
  color: $support-color-white !important; 
  font-size: 14px !important;
  font-weight: 700 !important;
  padding: 10px 16px !important;
}
button.swal2-styled:hover{
  background: $red-dark !important;
  border: 1px solid $red-dark !important;
  color: $support-color-white !important; 
}
.swal2-styled:focus{
  box-shadow: none !important;
}

/** Custom CSS for Toggle Switch **/
.toggle.btn {
  border-color: $grey-secondary-50 !important;
  &:hover{
    border-color: $grey-secondary-50 !important;
    background-color: $support-color-emerald !important;
  }
}

.toggle-handle{
  background-color: $grey-secondary-25 !important;
  border-color: $grey-secondary-25 !important;
  &:active, &:hover{
    background-color: $grey-secondary-25 !important;
    border-color: $grey-secondary-25 !important;
  }
}

.toggle-off.btn {
  color: $grey-primary !important;
  font-weight: normal !important;
  background-color: $support-color-white !important;
  &:hover{
    background-color: $support-color-emerald !important;
    border-color: $support-color-emerald !important;
    color: $support-color-white !important;
  }
}

.toggle-on.btn{
  color: $support-color-white !important;
  font-weight: normal !important;
  background-color: $support-color-emerald-k40 !important;
  border-color: $support-color-emerald-k40 !important;
  &:hover{
    color: $support-color-white !important;
    background-color: $support-color-emerald !important;
  }
}