.register-container{
    margin-top: 100px;
    max-width:480px;
    margin-left:auto;
    margin-right:auto;
    padding:20px 40px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px; 
    background-color: #fff;

    @include MQ(Sneg){
        padding:20px 0;
        margin-top:0px;
        box-shadow: none;
        border:none;
    }
    
    .panel-heading{
        text-align: center;
    }
    .panel-heading{
        margin-bottom: 7px;
        h3{
            
            font-size: 22px;
            font-weight: 500;
        }
        h4{
            font-size: 15sp;
            font-weight: 400;
        }
    }
    .agm-logo{
        margin-bottom: 20px;
    }
    .nav.nav-tabs{
        border:none;
        display: inline-block;
        margin-bottom: 21px;

        li{
            margin:0 15px;
        }

        li.active{
            a{
                border-bottom:2px solid ;
                border-top:0;
                border-left:0;
                border-right:0;
                color:$form-input-color;
            }
            
        }

        li.disabled{
            a{
                border-bottom:2px solid transparent;
                border-top:0;
                border-left:0;
                border-right:0;
                color:#8a8888;
            }
            
        }
        li a{
            font-size:13sp;
            padding: 0 0 3px;
            border-bottom:2px solid transparent;
            border-top:0;
            border-left:0;
            border-right:0;
            color:#8a8888;
            &:hover{
                background-color: #fff;
                
            }
            &:focus{
                background-color: #fff;
            }
        }
        
    }
    .tab-pane{
        .input-box{
            min-height: 328px;
        }
    }

    .form-group{
        input.form-control{
            margin-bottom: 11px;
            font-size: 15px;
            padding: 10px 16px;
            height: 45px;
            background-color: $gray-100;
            border-radius: 2px;
            font-weight: bold;
            color:$form-input-color;
            box-shadow: none;
            border:1px solid #ddd;
        }
        input::placeholder {
            color: $form-input-color;
          }
        .input-group{
            width: 100%;
        }
        .check-box {
            margin-top:9px;
            display: block;
            align-items: center;
            position: relative;
            padding-left: 31px;
            margin-bottom: 12px;
            height: 20px;
            cursor: pointer;
            
            font-size: 15px;

            input{
                opacity: 0;
                position: absolute;
                left: 0;
            }
            .styled-box{
                position: absolute;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: $gray-100;
                border:1px solid #ccc;
                border-radius: 2px;

                &:after {
                    display: none;
                    content: '';
                    position: absolute;
                    left: 5px;
                    width: 8px;
                    height: 15px;
                    border: solid $form-input-color;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            input:checked ~ .styled-box:after {
                display: block;
            }
        }
           
    }
    .btn-orange{
        text-transform: uppercase !important;
        font-weight: bold;
        padding-top:14px;
        padding-bottom: 14px;
        &:hover {
            color: #fff;
        }
    }
    .btn-back{
        margin-top:auto;
        margin-bottom: auto;
    }
    .btn:disabled{
        background-color: #ccc;
        color:$gray-600;
    }
    .help-num{
        font-size:16px;
        margin-top:20px;
    }
    
    label{
        font-size: 15px;
        color:$form-input-color;
    }
    select.form-control{
        font-size: 15px;
        height: 45px;
        background-color: $gray-100;
        border-radius: 3px;
        font-weight: bold;
        color:$form-input-color;
        margin-bottom:15px; 
        border:1px solid #ddd;
        box-shadow: none;
        option{
            font-size: 15px;
            padding: 10px 15px;
            border-radius: 3px;
        }
    }

    .has-error{
        position: relative;
        .help-block{
            top:45px;
        }
    }
}

.error{
    color:red;
}

input.error{
    border-color:red !important;
}

.hide{
    display: none;
}

.no-border{
    a{
        border-bottom-color:transparent;
    }
}