.masthead {
  // background: $blue-dark;
  // color: #fff;
  background: $header_grey !important;
  color: #5a5a5a !important;
  padding: 15px 0;
  &.has-subnav {
    margin-bottom: 0;
  }
  // .branding-wrapper {
  // padding: 15px 0;
  // background: $blue-dark;
  // }
  // .nav-wrapper {
  //  background: $neutral-color-dark;
  // }
  .row {
    margin: 0;
  }
  // .container {
  //   max-width: 970px;
  // }
  .branding {
    padding: 0 20px;
  }
  .links {
    a {
      padding: 10px 12px 10px 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: $support-color-black;
      &:hover, &:focus, &:active{
        text-decoration: none !important;
        color: $red-light;
        font-weight: 400;
      }
      &.active{
        color: $red-light !important;
        font-weight: bold !important;
      }
    }
  }
  h1 {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 3px;
    font-weight: 700;
    .light-theme.agm-screen & {
      color: #5a5a5a;
    }
  }
  h2 {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0;
    .light-theme.agm-screen & {
      color: #5a5a5a;
    }
  }
  h3 {
    color: $support-color-black;
    margin: 0;
    .light-theme.agm-screen & {
      color: $support-color-black;
    }
  }
}

.agm-phone {
  margin-right: 15px;
  //display: flex;
  justify-content: center;
  margin-bottom: 3px;
  @include MQ(L) {
    display: block;
  }
}

.agm-phone-label {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
  // color: rgba(255,255,255,0.5);
  color: $support-color-black;
  margin-top: 5px;
  margin-right: 3px;
  .light-theme & {
    color: $support-color-black;
  }
}

.links-with-phone {
  @include MQ(L) {
    display: flex;
    align-items: center;
  }
}

.agm-phone-number {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.54px;
  line-height: 18px;
  color: $support-color-black;
  .light-theme & {
    color: $support-color-black;
    font-weight: normal;
  }
}

.sub-nav-wrapper {
  // background: darken($blue-dark, 5%);
  background: $support-color-white;
  border-bottom: 1px solid #d6d6d6;
  padding: 8px 0px;
  margin-bottom: 30px;
}

.sub-nav {
  // text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-nav-link {
  font-size: 14px;
  font-weight: 400;
  padding: 15px 40px;
  display: block;
  color: $support-color-black;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
    color: $red-light;
    font-weight: 500;
  }
  &.active,
  &:active,
  &:focus {
    background: $red-light;
    color: $support-color-white;
    text-decoration: none;
    border-radius: 5px;
    padding: 10px 12px;
    .sub-menu-icons {
      color: rgba(0, 0, 0, 0.5);
    }
    .sub-menu-icon {
      color: $support-color-white !important;
    }
  }
}

.sub-menu-icons {
  padding-left: 5px;
}

.sub-menu-icon {
  padding-left: 3px;
  padding-right: 3px;
  color: $grey-secondary-75 !important;
  i {
    color: $grey-secondary-50 !important;
    margin-right: 2px;
  }
}

.masthead-links {
  @include MQ(Sneg) {
    float: none;
    text-align: center;
    margin-top: 10px;
    width: 100%;
  }
}

.footer-custom h1 {
  font-size: 16px;
  padding-right: 15px;
  color: #fff;
}

.reg-footer {
  position: relative;
  bottom: 0px;
  width: 100%;
  @media (max-width: 768px) {
    position: fixed;
  }
  @media (max-width: 575px) {
    position: relative;
  }
}

.footer-custom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 768px) {
    display: block;
  }
}
.align-cl {
  text-align: center;
  @media (max-width: 768px) {
    text-align: right;
    padding-top: 12px;
  }
  @media (max-width: 575px) {
    text-align: left;
    padding-top: 10px;
  }
}
.footer-bg-reg {
  background: #242424 !important;
  color: #fff !important;
  padding: 10px 0;

  a {
    color: #ccc !important;
    text-decoration: underline;
  }
  h2 {
    color: #ccc !important;
  }
}
.footer-bg {
  background: #242424 !important;
  height: 95px;
  a {
    color: #ccc !important;
  }
  a.footer-link {
    text-decoration: underline !important;
  }
  h1 {
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0.06px;
    line-height: 25px;
    color: #fff;
    margin-bottom: 6px;
  }
  h2 {
    font-size: 12px;
    letter-spacing: 0.03px;
    line-height: 15px;
    color: #ccc !important;
    font-weight: 400;
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 992px) {
  .agm-screen .masthead .logo {
    float: none !important;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: auto;
    text-align: center;
  }
}

@media screen and (max-width: 768px) and (min-width: 520px) {
  .links-wrapper {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .agm-screen .masthead .main_controls a {
    padding: 9px 14px !important;
  }
}

@media only screen and (max-width: 767px) {
  .agm-actions .card-link.footer-box {
    padding: 5rem 15px !important;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    max-width: 80% !important;
  }
}

@media only screen and (max-width: 519px) {
  .links-wrapper {
    display: block;
    margin-top: 1rem;
  }
  .main_controls {
    float: none !important;
  }
  .agm-screen .masthead-links a {
    display: inline-block;
    width: 220px;
  }
}

body {
  margin-bottom: 0 !important;
  background: #fff !important;
}

h2.footer-tagline a {
  // color: #fff;
  text-decoration: none;
}
.agm-phone-number {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.54px;
  //line-height: 15px;
}

.agm-phone-number .help-exit-color span {
  min-width: 65px;
  display: inline-block;
  font-size: 10px;
}