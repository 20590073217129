.text-small,
.text-sm {
  font-size: 12px;
}

.text-base {
  font-size: 14px;
}

.text-md {
  font-size: 18px;
}

.text-lg {
  font-size: 30px;
}

.text-subtle {
  color: #999;
}

.text-bold,
.font-weight-bold {
  font-weight: bold;
}

.btn-tools {
  color: #999;
  background: #f0f0f0;
  border: 1px solid #ddd;
  margin-left: 3px;
  font-size: 13px;
}

.container-sm {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.container-md {
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.bg-white {
  background-color: #fff;
}

.bg-light {
  background-color: #F6F6F6;
}

.border {
  border: 1px solid #ddd;
}

.border-left {
  border-left: 1px solid #ddd;
}

.border-right {
  border-right: 1px solid #ddd;
}

.border-top {
  border-top: 1px solid #ddd;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

// Flexbox Utility Classes

.flex { display: flex }

.flex-column  { flex-direction: column }

.flex-wrap    { flex-wrap: wrap }

.items-start    { align-items: flex-start }

.items-end      { align-items: flex-end }

.items-center   { align-items: center }

.items-baseline { align-items: baseline }

.items-stretch  { align-items: stretch }

.self-start    { align-self: flex-start }

.self-end      { align-self: flex-end }

.self-center   { align-self: center }

.self-baseline { align-self: baseline }

.self-stretch  { align-self: stretch }

.justify-start   { justify-content: flex-start }

.justify-end     { justify-content: flex-end }

.justify-center  { justify-content: center }

.justify-between { justify-content: space-between }

.justify-around  { justify-content: space-around }

.content-start   { align-content: flex-start }

.content-end     { align-content: flex-end }

.content-center  { align-content: center }

.content-between { align-content: space-between }

.content-around  { align-content: space-around }

.content-stretch { align-content: stretch }

/* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */

.flex-auto {
  flex: 1 1 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}

.flex-none { flex: none }

.order-0 { order: 0 }

.order-1 { order: 1 }

.order-2 { order: 2 }

.order-3 { order: 3 }

.order-last { order: 99999 }


// Spacing Utilty Classes

$space-1: 5px;
$space-2: 15px;
$space-3: 30px;
$space-4: 50px;

.p0  { padding: 0 }

.pt0 { padding-top: 0 }

.pr0 { padding-right: 0 }

.pb0 { padding-bottom: 0 }

.pl0 { padding-left: 0 }

.px0 { padding-left: 0; padding-right:  0 }

.py0 { padding-top: 0;  padding-bottom: 0 }

.p1  { padding:        $space-1 }

.pt1 { padding-top:    $space-1 }

.pr1 { padding-right:  $space-1 }

.pb1 { padding-bottom: $space-1 }

.pl1 { padding-left:   $space-1 }

.py1 { padding-top:    $space-1; padding-bottom: $space-1 }

.px1 { padding-left:   $space-1; padding-right:  $space-1 }

.p2  { padding:        $space-2 }

.pt2 { padding-top:    $space-2 }

.pr2 { padding-right:  $space-2 }

.pb2 { padding-bottom: $space-2 }

.pl2 { padding-left:   $space-2 }

.py2 { padding-top:    $space-2; padding-bottom: $space-2 }

.px2 { padding-left:   $space-2; padding-right:  $space-2 }

.p3  { padding:        $space-3 }

.pt3 { padding-top:    $space-3 }

.pr3 { padding-right:  $space-3 }

.pb3 { padding-bottom: $space-3 }

.pl3 { padding-left:   $space-3 }

.py3 { padding-top:    $space-3; padding-bottom: $space-3 }

.px3 { padding-left:   $space-3; padding-right:  $space-3 }

.p4  { padding:        $space-4 }

.pt4 { padding-top:    $space-4 }

.pr4 { padding-right:  $space-4 }

.pb4 { padding-bottom: $space-4 }

.pl4 { padding-left:   $space-4 }

.py4 { padding-top:    $space-4; padding-bottom: $space-4 }

.px4 { padding-left:   $space-4; padding-right:  $space-4 }

.m0  { margin:        0 }

.mt0 { margin-top:    0 }

.mr0 { margin-right:  0 }

.mb0 { margin-bottom: 0 }

.ml0 { margin-left:   0 }

.mx0 { margin-left:   0; margin-right:  0 }

.my0 { margin-top:    0; margin-bottom: 0 }

.m1  { margin:        $space-1 }

.mt1 { margin-top:    $space-1 }

.mr1 { margin-right:  $space-1 }

.mb1 { margin-bottom: $space-1 }

.ml1 { margin-left:   $space-1 }

.mx1 { margin-left:   $space-1; margin-right:  $space-1 }

.my1 { margin-top:    $space-1; margin-bottom: $space-1 }

.m2  { margin:        $space-2 }

.mt2 { margin-top:    $space-2 }

.mr2 { margin-right:  $space-2 }

.mb2 { margin-bottom: $space-2 }

.ml2 { margin-left:   $space-2 }

.mx2 { margin-left:   $space-2; margin-right:  $space-2 }

.my2 { margin-top:    $space-2; margin-bottom: $space-2 }

.m3  { margin:        $space-3 }

.mt3 { margin-top:    $space-3 }

.mr3 { margin-right:  $space-3 }

.mb3 { margin-bottom: $space-3 }

.ml3 { margin-left:   $space-3 }

.mx3 { margin-left:   $space-3; margin-right:  $space-3 }

.my3 { margin-top:    $space-3; margin-bottom: $space-3 }

.m4  { margin:        $space-4 }

.mt4 { margin-top:    $space-4 }

.mr4 { margin-right:  $space-4 }

.mb4 { margin-bottom: $space-4 }

.ml4 { margin-left:   $space-4 }

.mx4 { margin-left:   $space-4; margin-right:  $space-4 }

.my4 { margin-top:    $space-4; margin-bottom: $space-4 }

.mxn1 { margin-left: -$space-1; margin-right: -$space-1; }

.mxn2 { margin-left: -$space-2; margin-right: -$space-2; }

.mxn3 { margin-left: -$space-3; margin-right: -$space-3; }

.mxn4 { margin-left: -$space-4; margin-right: -$space-4; }

.ml-auto { margin-left: auto }

.mr-auto { margin-right: auto }

.mx-auto { margin-left: auto; margin-right: auto; }

.btn-orange { background-color: $link-orange; color:#fff; text-transform: uppercase; }

.uppercase { text-transform: uppercase; }

.cursor--pointer { cursor: pointer; }