// Management - Sidebar

.sidebar {
	background: #fff;
	position: fixed;
	width: 270px;
	height: 100%;
	padding: 10px;
	top: 0px;
	z-index: 1000;
	right: -270px;
	transition: all 0.3s ease;
	overflow-y: scroll;
	@include MQ(S) {
		padding: 24px;
	  width: 360px;
	  right: -360px;
	}
	&.isOpen {
		right: 0px;
		box-shadow: -1px 0 3px 1px rgba(#000, 0.1);
	}
	.sidebar-content {
		// max-height: calc(100vh - 85px);
	  overflow: auto;
	}
	.pulse {
		background-color: green;
		height: 100px;
		width: 100px;
	}
	.sidebar-tab {
		content: '';
		position: absolute;
		top: 18px;
		left: -46px;
		width: 46px;
		height: 46px;
		padding: 11px 10px;
		display: block;
		border-radius: 4px 0 0 4px;
		box-shadow: -1px 0 3px 1px rgba(#000, 0.1);
		cursor: pointer;
	}
	.sidebar-tab-comments {
		background-color: $blue-light;
		&:hover, &:focus {
			text-decoration: none;
			background-color: darken($blue-light,6%);
		}
	}
	.sidebar-tab-phone {
		top: 70px;
		background-color: $green;
		background-image: url(../images/phone.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 25px 25px;
		&:hover, &:focus {
			text-decoration: none;
			background-color: darken($green,6%);
		}
	}
	.sidebar-tab-close {
		color: rgba(#fff,0.6);
		font-size: 26px;
		text-align: right;
		margin: -6px 0 10px 0;
		display: block;
		cursor: pointer;
		&:hover {
			color: #fff;
		}
	}
	.question-meta {
		padding: 5px 15px;
		margin: 15px -15px -15px -15px;
		background: #e9eaee;
		border-top: 1px solid #cfcfcf;
		text-transform: uppercase;
		font-weight: normal;
		color: #999;
		font-size: 11px;
		text-align: center;
	}
}

