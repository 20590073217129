.btn-logout {
  color: $support-color-black;
  // border-color: $support-color-black;
  // font-size: 16px;
  font-weight: 400;
  &:hover,
  &:active,
  &:focus {
    color: $red-light;
  }
}

.btn-minimal {
  background: transparent;
  // color: $blue-dark;
  color: $red-light;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.btn-red {
  background: $red-light;
  color: #fff;
  &:hover,
  &:focus {
    color: #fff;
    background: $red-dark;
  }
}

.btn-black-outline {
  background: $support-color-white;
  border: 1px solid $support-color-black;
  color: $support-color-black;
  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background: $support-color-black !important;
    border: 1px solid $support-color-black !important;
  }
}

.btn-dropdown-header {
  background: $support-color-white;
  border: 1px solid $support-color-black;
  color: $support-color-black;
  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background: $red-light !important;
    border: 1px solid $red-light !important;
  }
}

.btn-standard {
  background: $grey-secondary-5;
  color: $support-color-black;
  border: none;
  font-weight: 700;
  text-align: center;
  &:hover,
  &:focus {
    color: $red-light;
  }
}

.btnCustom {
  color: #000 !important;
  background: #f3f3f3 !important;
  border: 1px solid #ddd !important;
  font-size: 14px !important;
  padding: 10px 16px !important;
  font-weight: 700 !important;
}

.btnCustom:hover {
  color: #fff !important;
  background: #e60000 !important;
  border: 1px solid #e60000 !important;
}

.btn-icon-grey {
  color: $support-color-black;
  background: #f3f3f3;
  border: 1px solid #ddd;
  font-size: 12px;
  padding: 1px 5px;
}
.btn-icon-grey:hover {
  background: $red-light;
  color: $support-color-white;
  border: 1px solid $red-light;
}

.btn-default {
  color: $support-color-black !important;
  background: #f3f3f3 !important;
  border: 1px solid #ddd !important;
  i {
    margin-right: 5px;
  }
  &:hover {
    background: $red-light !important;
    color: $support-color-white !important;
    border: 1px solid $red-light !important;
  }
}

.btn-secondary {
  color: $support-color-black !important;
  background: #f3f3f3 !important;
  border: 1px solid #ddd !important;
  i {
    margin-right: 5px;
  }
  &:hover {
    background: $red-light !important;
    color: $support-color-white !important;
    border: 1px solid $red-light !important;
  }
}

.btn-remove {
  color: $red-light !important;
  background: $support-color-white !important;
  border: 1px solid $red-light !important;
  &:hover {
    background: $red-light !important;
    color: $support-color-white !important;
    border: 1px solid $red-light !important;
  }
}

.btn-blue {
  background: $blue-primary;
  color: #fff;
  &:hover,
  &:focus {
    color: #fff;
    background: darken($blue-primary, 5%);
  }
}

.btn-orange1 {
  background: $support-color-orange;
  color: #fff;
  &:hover,
  &:focus {
    color: #fff;
    background: darken($support-color-orange, 5%);
  }
}

.btn-green {
  background: $support-color-green-K40;
  color: #fff;
  &:hover,
  &:focus {
    color: #fff;
    background: darken($support-color-green, 5%);
  }
}

.btn-emerald-outline {
  background: $support-color-white;
  border: 1px solid $support-color-emerald-k40;
  color: $support-color-emerald-k40;
  &:hover,
  &:focus {
    color: $support-color-white;
    background: darken($support-color-emerald, 5%);
  }
}

.btn-lightgrey {
  background: $grey-secondary-25;
  color: #000;
  &:hover,
  &:focus {
    color: #000;
    background: darken($grey-secondary-25, 5%);
  }
}

.btn-lg {
  border-radius: 4px;
}

.btn-add {
  color: $blue-light;
  font-weight: bold;
}


