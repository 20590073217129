toggle-switch {
  display: block;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.toggle-switch {
  label {
    padding: 0 10px;
  }
  label.active {
    color: $link-color;
  }
}

.toggles-wrapper {
  .toggle-switch {
    label {
      padding: 0 3px;
    }
  }
}

.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.cmn-toggle-round + label {
  padding: 2px;
  width: 60px;
  height: 30px;
  background-color: #dddddd;
  border-radius: 60px;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f6f6f6;
  border-radius: 30px;
  transition: background 0.4s;
}
input.cmn-toggle-round + label:after {
  width: 29px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: margin 0.4s;
}

input.cmn-toggle-round:checked + label:before {
  background: $brand-primary;
}

input.cmn-toggle-round:checked + label:after {
  margin-left: 30px;
}
