.add-topic-form{
   display: flex;
   .form-control{
       width: 33% !important;
   }
}
.form-control {
    &.question-note {
        width: 100%;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */        
    }
}

.fa-spin { 
    position: static !important;
}