.dashboard {
  // max-width: 900px;
  // margin: 0 auto;
  padding-bottom: 50px;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
  .btn {
    font-size: 16px;
    border-radius: 0;
    padding: 10px 25px;
    border: 1px solid #ccc;
    background: #dbdbdb;
    color: #777;
    &.active {
      background: #fff;
      color: $link-color;
    }
    &:hover {
      background: #eee;
    }
    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      // margin-left: -1px;
    }
  }
}

.theme-preview {
  margin-bottom: 30px !important;
  .container {
    width: auto !important;
  }
  .btn {
    border: none;
  }
  &.light-theme {
    h1 {
      color: #333;
    }
    h2 {
      color: rgba(0,0,0,0.4);
    }
  }
}

// AGM

.dashboard-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-agm-list {
  background: #fff;
  border: 1px solid #e6e6e6;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  li {
    clear: both;
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    overflow: hidden;
    padding: 10px;
    display: flex;
    &.no-result {
      text-align: center;
    }
  }
  .branding {
    width: 210px;
    // float: left;
  }
  .datetime{
    display: flex;
    align-items: center;
    font-size: 90%;
    width: 150px;
    justify-content: center;
}
  .logo {
    max-width: 50px;
    max-height: 50px;
    margin-right: 10px;
    overflow: hidden;
    margin-top: 0;
    @include MQ(S) {
      width: 60px;
      //margin-top: -10px;
      float: left;
    }
    @include MQ(Sneg) {
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .company {
    font-weight: normal;
    font-size: 16px;
    margin-top: 13px;
    margin-left: 65px;
    a{
      color: $support-color-black;
    }
  }
  .tools {
    // width: 620px;
    // float: left;
    padding-top: 8px;
    margin-left: auto;
    .inner {
      float: right;
    }
  }
  .agm-status {
    font-size: 75%;
    &.ready {
      color: #547D3A;
      font-weight: 550;
    }
    &.not-ready {
      color: $red-light;
      font-weight: 550;
    }
    a {
      color: $text-color;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .failed-connection {
    margin-top: 5px;
    padding: 7px 15px;
  }
  .btn {
    color: $support-color-black;
    background: #f3f3f3;
    border: 1px solid #ddd;
    margin-left: 2px;
    font-size: 13px;
    padding: 6px 8px;
    i {
      margin-right: 5px;
    }
    &:hover {
      background: $red-light;
      color: $support-color-white;
      border: 1px solid $red-light;
    }
    &.published {
      background: $brand-success;
      color: #fff;
      border-color: #4cae4c;
    }
    &.archived {
       background: $brand-danger;
       color: #fff;
       border-color: #d43f3a;
    }
    &.closing {
      background: #333;
      color: #fff;
      .fa {
         animation: fa-spin 2s infinite linear;
         &:before {
           content: '\f021';
         }
      }
    }
    &.closed {
      background: #333;
      color: #fff;
    }
    &.notified {
      background: $brand-warning;
      color: #fff;
    }
    &.submitting {
      .fa {
        animation: fa-spin 2s infinite linear;
        &:before {
          content: '\f021';
        }
      }
    }
  }
  .btn-col-1 {
    width: 72px;
  }
  .btn-col-4 {
    width: 126px;
  }
}

[ui-view="create"] {
  .dashboard-form {
    margin-top: 30px;
  }
  .dashboard-form-header {
    display: none;
  }
}

@keyframes slideup {
  from {
    transform: translate3D(0,100%,0);
  }
  to {
    transform: none;
  }
}

.dashboard-form {
  .unsaved-changes {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #222;
    color: #fff;
    z-index: 100;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    padding: 8px;
    animation-duration: 600ms;
    animation-name: slideup;
    letter-spacing: 1px;
  }
  // label {
  //   font-weight: normal;
  // }
  .image-upload-placeholder {
    background: #eee;
    height: 148px;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #ccc;
    overflow: hidden;
    .fa {
      font-size: 30px;
      padding-top: 20px;
      color: #999;
    }
  }
  .file-upload {
    .image-upload-placeholder {
      height: auto;
      .fa {
        padding: 6px;
      }
    }
  }
  .image-upload,
  .file-upload {
    .btn {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background: $blue-dark;
      border-color: darken($blue-dark, 5%);
      color: #fff;
      i {
        margin-right: 5px;
      }
    }
  }
}

.dashboard-form-header {
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
  .branding {
    text-align: center;
  }
  .logo {
    max-width: 90px;
    margin: 0 auto 10px;
  }
  .company {
    font-weight: 700;
    font-size: 30px;
    margin-top: 0;
  }
  .tools {
    float: right;
    a {
      margin-left: 5px;
      &:hover {
        background: $link-color;
        color: #fff;
      }
    }
    i {
      margin-right: 5px;
      font-size: 20px;
      position: relative;
      top: 1px;
    }
  }
}

.dashboard-section {
  background: #fff;
  margin-bottom: 30px;
  margin-top: 30px;
  border: 1px solid #e6e6e6;
  //box-shadow: 0 0 5px rgba(0,0,0,0.1);
}

.dashboard-section-title {
  h3 {
    margin: 0;
    padding: 0;
    // font-size: 16px;
    // font-weight: 700;
    // border-bottom: 1px solid #e6e6e6;
    padding: 20px 40px !important;
    // background: #f9f9f9;
    background-color: #fff !important;
    border: none;
    font-weight: 400;
    font-size: 24px;
    
  }
}

.dashboard-section-inner {
  padding: 20px 40px 30px 40px;
}

.dashboard-form-footer {
  padding: 10px 40px 40px 40px;
  text-align: center;
  .btn {
    font-size: 18px;
  }
}

// Test results

.test-results-wrapper {
  background: #444;
  color: #fff;
  padding: 20px 30px;
  margin-top: 10px;
  .close {
    color: #ccc;
    opacity: 1;
    text-shadow: none;
    position: relative;
    top: -6px;
    right: -11px;
  }
  h3 {
    font-size: 14px;
    margin-top: 0;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    border-bottom: 1px solid #333;
    position: relative;
    padding-left: 35px;
    &:before {
      position: absolute;
      font-family: FontAwesome;
      left: 5px;
      top: 5px;
      font-size: 20px;

    }
    &.fail {
      &:before {
        content: '\f057';
        color: $brand-danger;
      }
    }
    &.pass {
      &:before {
        content: '\f058';
        color: $brand-success;
      }
    }
  }
}

// Kiosk

.dashboard-kiosk {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  .panel-body {
    padding: 20px 30px
  }
  .person-name {
    font-size: 16px;
    font-weight: 700;
  }
  .person-address {
    color: $support-color-black;
    font-weight: 400;
  }
}

.kiosk-search {

  padding: 30px 0;
}

.kiosk-loading {
  position: relative;
  .fa-spin {
    position: static;
  }
}

.pin-result {
  font-size: 36px;
  font-weight: bold;
}

// Bootbox Modals

.bootbox {
  .btn-default {
    display: none;
  }
  .modal-footer {
    text-align: center;
    padding: 0;
    .btn-primary {
      display: block;
      width: 100%;
      margin: 0;
      padding: 15px;
      font-size: 20px;
      border-radius: 0 0 4px 4px;
    }
  }
  .bootbox-body {
    font-size: 20px;
    padding: 15px 40px;
  }
}

.bootbox-close-button {
  display: none;
}
ul.password_preview {
  padding: 0;
  list-style: none; 
}

.password_preview i {
  margin: 5px;
}