.voting-card {
  text-align: center;
  // @include MQ(XS) {
  //   padding: 0 30px;
  // }
}

.card-header {
  margin-bottom: 30px;
  h3 {
    font-size: 30px;
    font-weight: normal;
    margin-top: 0;
  }
  p {
    font-size: 16px;
    color: #777;
    font-weight: 300;
  }
}

.card-login {
  padding: 20px 0 30px 0;
  @include MQ(XS) {
    background: #f0f0f0;
    padding: 20px 30px 30px 30px;
  }
  margin-bottom: 15px;
  h4 {
    text-transform: uppercase;
    margin-top: 0;
    font-weight: bold;
  }
  form {
    text-align: left;
  }
  label {
    font-weight: normal;
  }
  .btn {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    border-radius: 0;
    background: $company-default;
    border-color: darken($company-default, 5%);
    padding-top: 8px;
    padding-bottom: 8px;
    &:hover {
      background: darken($company-default, 5%);
    }
  }
}

.jointholders {
  .bootstrap-select {
    width: 100% !important;
  }
  .btn {
    background: #fff !important;
    color: $text-color !important;
    border-color: #ccc !important;
  }
}

.or {
  margin-bottom: 15px;
  position: relative;
  &:before {
    content: '';
    background: #eee;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
  }
  h5 {
    background: #ccc;
    color: #666;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    line-height: 40px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.resolution {
  text-align: left;
}

// Country Select

.country-select-btns {
  display: inline-block;
  vertical-align: top;
  width: 120px;
  padding-top: 5px;
  min-height: 35px;
}

.country-select-dropdown {
  display: inline-block;
  .btn {
    padding-top: 6px;
    padding-bottom: 6px;
    &:focus {
      outline: none;
      background: #ccc !important;
    }
  }
}