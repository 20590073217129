
// Converted Variables

$space-1: .5rem !default;
$space-2: 1rem !default;
$space-3: 2rem !default;
$space-4: 4rem !default;

// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Responsive Padding */

@media #{$breakpoint-sm} {

  .sm-p0  { padding:        0 }
  .sm-pt0 { padding-top:    0 }
  .sm-pr0 { padding-right:  0 }
  .sm-pb0 { padding-bottom: 0 }
  .sm-pl0 { padding-left:   0 }
  .sm-px0 { padding-left:   0; padding-right:  0 }
  .sm-py0 { padding-top:    0; padding-bottom: 0 }

  .sm-p1  { padding:        $space-1 }
  .sm-pt1 { padding-top:    $space-1 }
  .sm-pr1 { padding-right:  $space-1 }
  .sm-pb1 { padding-bottom: $space-1 }
  .sm-pl1 { padding-left:   $space-1 }
  .sm-px1 { padding-left:   $space-1; padding-right:  $space-1 }
  .sm-py1 { padding-top:    $space-1; padding-bottom: $space-1 }

  .sm-p2  { padding:        $space-2 }
  .sm-pt2 { padding-top:    $space-2 }
  .sm-pr2 { padding-right:  $space-2 }
  .sm-pb2 { padding-bottom: $space-2 }
  .sm-pl2 { padding-left:   $space-2 }
  .sm-px2 { padding-left:   $space-2; padding-right:  $space-2 }
  .sm-py2 { padding-top:    $space-2; padding-bottom: $space-2 }

  .sm-p3  { padding:        $space-3 }
  .sm-pt3 { padding-top:    $space-3 }
  .sm-pr3 { padding-right:  $space-3 }
  .sm-pb3 { padding-bottom: $space-3 }
  .sm-pl3 { padding-left:   $space-3 }
  .sm-px3 { padding-left:   $space-3; padding-right:  $space-3 }
  .sm-py3 { padding-top:    $space-3; padding-bottom: $space-3 }

  .sm-p4  { padding:        $space-4 }
  .sm-pt4 { padding-top:    $space-4 }
  .sm-pr4 { padding-right:  $space-4 }
  .sm-pb4 { padding-bottom: $space-4 }
  .sm-pl4 { padding-left:   $space-4 }
  .sm-px4 { padding-left:   $space-4; padding-right:  $space-4 }
  .sm-py4 { padding-top:    $space-4; padding-bottom: $space-4 }

}

@media #{$breakpoint-md} {

  .md-p0  { padding:        0 }
  .md-pt0 { padding-top:    0 }
  .md-pr0 { padding-right:  0 }
  .md-pb0 { padding-bottom: 0 }
  .md-pl0 { padding-left:   0 }
  .md-px0 { padding-left:   0; padding-right:  0 }
  .md-py0 { padding-top:    0; padding-bottom: 0 }

  .md-p1  { padding:        $space-1 }
  .md-pt1 { padding-top:    $space-1 }
  .md-pr1 { padding-right:  $space-1 }
  .md-pb1 { padding-bottom: $space-1 }
  .md-pl1 { padding-left:   $space-1 }
  .md-px1 { padding-left:   $space-1; padding-right:  $space-1 }
  .md-py1 { padding-top:    $space-1; padding-bottom: $space-1 }

  .md-p2  { padding:        $space-2 }
  .md-pt2 { padding-top:    $space-2 }
  .md-pr2 { padding-right:  $space-2 }
  .md-pb2 { padding-bottom: $space-2 }
  .md-pl2 { padding-left:   $space-2 }
  .md-px2 { padding-left:   $space-2; padding-right:  $space-2 }
  .md-py2 { padding-top:    $space-2; padding-bottom: $space-2 }

  .md-p3  { padding:        $space-3 }
  .md-pt3 { padding-top:    $space-3 }
  .md-pr3 { padding-right:  $space-3 }
  .md-pb3 { padding-bottom: $space-3 }
  .md-pl3 { padding-left:   $space-3 }
  .md-px3 { padding-left:   $space-3; padding-right:  $space-3 }
  .md-py3 { padding-top:    $space-3; padding-bottom: $space-3 }

  .md-p4  { padding:        $space-4 }
  .md-pt4 { padding-top:    $space-4 }
  .md-pr4 { padding-right:  $space-4 }
  .md-pb4 { padding-bottom: $space-4 }
  .md-pl4 { padding-left:   $space-4 }
  .md-px4 { padding-left:   $space-4; padding-right:  $space-4 }
  .md-py4 { padding-top:    $space-4; padding-bottom: $space-4 }

}

@media #{$breakpoint-lg} {

  .lg-p0  { padding:        0 }
  .lg-pt0 { padding-top:    0 }
  .lg-pr0 { padding-right:  0 }
  .lg-pb0 { padding-bottom: 0 }
  .lg-pl0 { padding-left:   0 }
  .lg-px0 { padding-left:   0; padding-right:  0 }
  .lg-py0 { padding-top:    0; padding-bottom: 0 }

  .lg-p1  { padding:        $space-1 }
  .lg-pt1 { padding-top:    $space-1 }
  .lg-pr1 { padding-right:  $space-1 }
  .lg-pb1 { padding-bottom: $space-1 }
  .lg-pl1 { padding-left:   $space-1 }
  .lg-px1 { padding-left:   $space-1; padding-right:  $space-1 }
  .lg-py1 { padding-top:    $space-1; padding-bottom: $space-1 }

  .lg-p2  { padding:        $space-2 }
  .lg-pt2 { padding-top:    $space-2 }
  .lg-pr2 { padding-right:  $space-2 }
  .lg-pb2 { padding-bottom: $space-2 }
  .lg-pl2 { padding-left:   $space-2 }
  .lg-px2 { padding-left:   $space-2; padding-right:  $space-2 }
  .lg-py2 { padding-top:    $space-2; padding-bottom: $space-2 }

  .lg-p3  { padding:        $space-3 }
  .lg-pt3 { padding-top:    $space-3 }
  .lg-pr3 { padding-right:  $space-3 }
  .lg-pb3 { padding-bottom: $space-3 }
  .lg-pl3 { padding-left:   $space-3 }
  .lg-px3 { padding-left:   $space-3; padding-right:  $space-3 }
  .lg-py3 { padding-top:    $space-3; padding-bottom: $space-3 }

  .lg-p4  { padding:        $space-4 }
  .lg-pt4 { padding-top:    $space-4 }
  .lg-pr4 { padding-right:  $space-4 }
  .lg-pb4 { padding-bottom: $space-4 }
  .lg-pl4 { padding-left:   $space-4 }
  .lg-px4 { padding-left:   $space-4; padding-right:  $space-4 }
  .lg-py4 { padding-top:    $space-4; padding-bottom: $space-4 }

}