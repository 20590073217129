.tour-start {
  width: 1px;
  margin-left: auto;
  margin-right: auto;
}

.tour-links-wrapper {
  pointer-events: none;
  position: relative;
}

.tour-links {
  position: absolute;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
}

// .resolution-wrapper:first-child {
//   background-color: red;
// }

.tour-item-wrapper {
  flex: auto;
  display: flex;
  justify-content: center;
}

.tour-video {
  width: 1px;
  height: 1px;
}

.tour-slides {
  height: 1px;
  width: 1px;
}

// Hide arrows
.tour-tourDashboard-0,
.tour-tourDashboard-6 {
  .arrow {
    display: none;
  }
}

.popover {
  padding: 0;
  color: #fff;
  .popover-title {
    font-size: 20px;
  }
  .arrow {
    &:after {

    }
  }
}

.popover-navigation {
  padding: 9px 14px;
  .btn {
    color: $blue-light !important;
    border-color: transparent !important;
    background: #222 !important;
  }
  .btn[data-role="end"] {
    margin-left: auto;
  }
}